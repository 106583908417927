import Richtext from "@src/shared/richtext/Richtext";
import React from "react";
import { FormattedMessage } from "react-intl";

import { DisclaimerContainer, StaticDisclaimer } from "../../SearchResults.sc";

interface DisclaimerProps {
    content: string;
}

const Disclaimer: React.FC<DisclaimerProps> = ({ content }) => {
    return (
        <DisclaimerContainer>
            <Richtext content={content} />

            <StaticDisclaimer>
                <FormattedMessage
                    id="leaseme.searchresult.disclaimer"
                    defaultMessage="Die Beträge sind Richtwerte und stellen weder ein Angebot zum Abschluss eines Vertrags noch eine Aufforderung zur Abgabe eines Angebots dar. Die angegebenen Beträge decken nicht alle Kosten des Leasingvertrags. Gilt nur für Gewerbetreibende nach dem Unternehmensgesetz und für eingetragene Freiberufler. Wenden Sie sich bitte an den Händler, um ein spezifisches Angebot für das von Ihnen gewählte Fahrzeug zu erhalten."
                />
            </StaticDisclaimer>
        </DisclaimerContainer>
    );
};

export default Disclaimer;
