import * as Cancel from "@src/shared/assets/images/cancel.inline.svg";
import * as Times from "@src/shared/assets/images/times.inline.svg";
import styled from "styled-components";

export const FilterChip = styled.div`
    background-color: ${({ theme }) => theme.colors.borderLightGray};
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    white-space: nowrap;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 5px 15px;
    font-size: 18px;
    line-height: 30px;
`;

export const ChipsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`;

export const FilterToolbar = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        flex-direction: row;
        align-items: center;
    }
`;

export const ResetButton = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
    margin-top: 20px;
    justify-content: center;
    flex-direction: row-reverse;

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        margin-top: 0;
        margin-right: 1px;
        align-items: baseline;
        flex-direction: row;
    }
`;

export const CancelIcon = styled(Cancel)`
    width: 15px;
    height: 15px;
    fill: ${({ theme }) => theme.colors.porscheBankBlue};
`;

export const CancelSingleOptionIcon = styled(Times)`
    margin-right: -8px;
    margin-left: 8px;
    cursor: pointer;
    width: 15px;
    height: 15px;
    font-weight: 300;
`;

export const ResetButtonText = styled.span`
    margin-left: 10px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.porscheBankBlue};

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        font-size: 18px;
        line-height: 30px;
        margin-right: 10px;
    }
`;

export const FilterToolbarContainer = styled.div`
    padding: 0 20px 20px;

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        padding: 0 0 20px;
    }
`;

export const NoResultsText = styled.div`
    margin-bottom: 20px;
    line-height: 20px;
`;
