import Richtext from "@src/shared/richtext/Richtext";
import React from "react";

import { DisclaimerContainer } from "./Disclaimer.sc";

interface DisclaimerProps {
    content: string;
}

const Disclaimer: React.FC<DisclaimerProps> = ({ content }) => {
    return (
        <DisclaimerContainer>
            <Richtext content={content} />
        </DisclaimerContainer>
    );
};

export default Disclaimer;
