import useSearchResult from "@src/shared/context/searchresult/useSearchResult";
import SearchSort from "@src/shared/searchResults/searchfilter/SearchSort";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { SearchSortContainer, SearchTitleRoot, Title } from "../../SearchResults.sc";

interface SearchTitleProps {
    headline?: string;
}

const SearchTitle: React.FC<SearchTitleProps> = ({ headline }) => {
    const intl = useIntl();
    const searchResult = useSearchResult();

    if (searchResult.loading) {
        return null;
    }

    return (
        <SearchTitleRoot>
            <Title>
                {headline ? (
                    headline
                ) : (
                    <FormattedMessage
                        id="leaseme.vehicleList.title"
                        defaultMessage="{totalCount} {totalCount, plural, =1 {{single}} other {{plural}}} für Ihre Auswahl"
                        values={{
                            totalCount: searchResult.totalCount,
                            single: intl.formatMessage({
                                id: "leaseme.vehicleList.title.single",
                                defaultMessage: "Angebot",
                            }),
                            plural: intl.formatMessage({
                                id: "leaseme.vehicleList.title.plural",
                                defaultMessage: "Angebote",
                            }),
                        }}
                    />
                )}
            </Title>

            <SearchSortContainer>
                <SearchSort />
            </SearchSortContainer>
        </SearchTitleRoot>
    );
};

export default SearchTitle;
