import styled from "styled-components";

export const Container = styled.section`
    color: ${({ theme }) => theme.colors.porscheBankBlue};
`;

export const Heading = styled.h2``;

export const Body = styled.p`
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 18px;
    margin: 0;

    ${({ theme }) => theme.breakpoints.sm.mediaquery} {
        font-size: 14px;
        line-height: 20px;
    }
    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        font-size: 16px;
        line-height: 24px;
    }
`;
