import * as Chevron from "@src/shared/assets/images/chevron.inline.svg";
import * as GeoIcon from "@src/shared/assets/images/location.inline.svg";
import Image from "@src/shared/image/Image";
import { Link } from "gatsby";
import styled, { css } from "styled-components";

export const Root = styled(Link)`
    position: relative;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${({ theme }) => theme.colors.backgroundLightGray};
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    text-decoration: none;
    margin-bottom: 20px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        flex-direction: row;
    }
`;

export const ImageMobile = styled(Image)<{ defaultImage: boolean }>`
    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        display: none;
    }

    ${({ defaultImage }) =>
        defaultImage &&
        css`
            img {
                object-fit: cover;
            }
        `}
`;

export const ImageLarge = styled(Image)<{ defaultImage: boolean }>`
    margin-right: 15px;

    display: none;
    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        margin-right: 30px;
        display: block;
    }

    ${({ defaultImage }) =>
        defaultImage &&
        css`
            img {
                object-fit: cover;
            }
        `}
`;

export const TextContainer = styled.div`
    flex: 1;
    min-width: 0;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    padding: 0 20px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        padding: 0;
    }
`;

export const Title = styled.h4`
    margin: 10px 0;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    aligin-content: center;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        font-size: 24px;
        line-height: 36px;
    }
`;

export const Text = styled.p`
    margin: 5px 0;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 22px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        font-size: 18px;
        line-height: 24px;
    }
`;

interface LocationIconProps {
    marginLeft: boolean;
}

export const LocationIcon = styled(GeoIcon)<LocationIconProps>`
    fill: ${({ theme }) => theme.colors.porscheBankBlue};
    margin-right: 3px;
    margin-left: ${({ marginLeft }) => (marginLeft ? "3px" : 0)};
    vertical-align: top;
    margin-top: 4px;
    width: 14px;
    height: 14px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        margin-top: 5px;
    }
`;

export const VerticalAlignmentWrapper = styled.div`
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 15px;

    ${({ theme }) => theme.breakpoints.lg2.mediaquery} {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
`;

export const List = styled.div`
    display: flex;
    justify-content: flex-start;
    min-width: 0;
    margin: 10px 0;
    padding: 0;
    list-style: none;
`;

export const VehicleData = styled.div`
    width: 100%;
    display: flex;
    gap: 20px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        flex-basis: 50%;
        justify-content: space-between;
    }
`;

export const Column = styled.div`
    width: 50%;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        width: auto;
        min-width: 120px;
        margin-right: 20px;
    }
`;

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        flex-direction: row;
        gap: 20px;
        flex-basis: 50%;
    }
`;

export const ListItem = styled.div`
    font-weight: 300;
    font-size: 14px;
    line-height: 30px;
    white-space: break-spaces;
`;

export const PriceAndLinkWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        flex-wrap: wrap;
        align-items: center;
        max-width: 300px;
        align-self: flex-end;
    }

    ${({ theme }) => theme.breakpoints.lg2.mediaquery} {
        align-self: flex-start;
    }
`;

export const PriceWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

export const DownPayment = styled.div`
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 30px;
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`;

export const DownPaymentPrice = styled.span`
    font-weight: 600;
    padding-right: 5px;
    display: block;
`;

export const VehicleLink = styled.div`
    display: flex;
    background: ${({ theme }) => theme.colors.porscheBankBlue};
    color: white;
    padding: 10px 20px;
    line-height: 24px;
    width: 100%;
    justify-content: center;
    align-self: flex-end;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        width: auto;
    }
`;

export const VehicleLinkText = styled.span`
    display: block;
    text-transform: uppercase;
`;

export const DWALink = styled.a`
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    outline: none;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        margin: 0;
        margin-right: 20px;
    }
`;

export const ChevronRight = styled(Chevron)`
    width: 15px;
    height: 15px;
    min-width: 15px;
    margin-left: 5px;
    margin-right: 5px;
    fill: ${({ theme }) => theme.colors.porscheBankBlue};
`;

export const DwaVehicleLinkContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        flex-direction: row;
        align-items: center;
        align-self: flex-end;
    }
`;
