import { pageSizeOptions } from "@src/shared/context/searchfilter/Options";
import useSearchFilter from "@src/shared/context/searchfilter/useSearchFilter";
import useSearchResult from "@src/shared/context/searchresult/useSearchResult";
import SingleChoiceFilter from "@src/shared/searchResults/searchfilter/filter/singlechoicefilter/SingleChoiceFilter";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import ReactPaginate from "react-paginate";

import { ChevronLeft, ChevronRight, PaginationSelection, PerPageSelection, Root, Text } from "./Pagination.sc";

export const Pagination: React.FunctionComponent = () => {
    const searchFilter = useSearchFilter();
    const searchResult = useSearchResult();

    if (!searchResult.vehicles || searchResult.vehicles.length === 0) {
        return null;
    }

    const onPageChange = (selectedItem: any) => {
        if (searchFilter.setPage) {
            searchFilter.setPage(selectedItem.selected + 1);
        }
    };

    return (
        <Root>
            <PerPageSelection>
                <Text>
                    <FormattedMessage id="leaseme.vehiclesPerSite" defaultMessage="Fahrzeuge pro Seite" />
                </Text>
                <SingleChoiceFilter
                    value={searchFilter.filter.size}
                    options={pageSizeOptions}
                    onChange={(value: any) => {
                        if (searchFilter.setSize) {
                            searchFilter.setSize(value);
                        }
                    }}
                    isSmall
                />
            </PerPageSelection>
            <PaginationSelection>
                <ReactPaginate
                    forcePage={searchResult.currentPage - 1}
                    pageCount={searchResult.totalPages}
                    breakLabel={"..."}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    onPageChange={onPageChange}
                    previousLabel={<ChevronLeft />}
                    nextLabel={<ChevronRight />}
                />
            </PaginationSelection>
        </Root>
    );
};
