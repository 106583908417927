import styled from "styled-components";

export const Root = styled.div`
    border-top: 1px solid ${({ theme }) => theme.colors.backgroundLightGray};
    padding: 40px 0;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        margin: 0 20px;
    }

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        margin: 0;
    }
`;
