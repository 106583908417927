import { GQLVehicleSearchResultVehicleFragment } from "@src/graphql.apollo.generated";
import * as React from "react";

import VehicleItem from "./vehicleItem/VehicleItem";
import { Root } from "./VehicleList.sc";

interface IVehicleListProps {
    vehicles: GQLVehicleSearchResultVehicleFragment[];
}
const VehicleList: React.FunctionComponent<IVehicleListProps> = ({ vehicles }) => {
    return (
        <Root>
            {vehicles.map((vehicle, index) => {
                return <VehicleItem vehicle={vehicle} key={index} />;
            })}
        </Root>
    );
};

export default VehicleList;
