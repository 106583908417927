import * as Chevron from "@src/shared/assets/images/chevron.inline.svg";
import styled from "styled-components";

export const Root = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 0 20px 40px;
    color: ${({ theme }) => theme.colors.porscheBankBlue};

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        padding: 0 0 40px;
    }
    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        flex-direction: row;
    }
`;

export const Text = styled.span`
    font-weight: 300;
    margin-left: 16px;
    flex-grow: 1;
    margin-right: 5px;
    font-size: 18px;
    line-height: 30px;

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        margin-left: 0;
    }
`;

export const ChevronLeft = styled(Chevron)`
    transform: rotate(180deg);
    width: 20px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ChevronRight = styled(Chevron)`
    width: 20px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const PerPageSelection = styled.div`
    display: flex;
    align-items: center;
`;

export const PaginationSelection = styled.div`
    ul {
        list-style: none;
        display: flex;
        align-items: center;
        padding: 0;
    }
    li {
        display: inline-block;
        margin: 0 10px;
    }

    li > a {
        display: inline-block;
        cursor: pointer;
        background-color: #fff;
        font-size: 18px;
        line-height: 30px;
        padding: 0 8px;
    }

    li > a:focus {
        outline: none;
    }

    li > a:hover {
        background-color: ${({ theme }) => theme.colors.porscheBankBlue};
        border-radius: 5px;
        color: #fff;
    }

    .selected a {
        font-weight: 600;
    }

    .break {
        margin: 0 5px;
    }

    .previous {
        margin: 0 10px 0 0;
        height: 30px;
        a:hover {
            background-color: ${({ theme }) => theme.colors.porscheBankBlue};
            border-radius: 5px;
            color: #fff;
        }
    }

    .next {
        margin: 0 0 0 10px;
        height: 30px;
        a:hover {
            background-color: ${({ theme }) => theme.colors.porscheBankBlue};
            border-radius: 5px;
            color: #fff;
        }
    }

    .disabled > a {
        color: #ccc;
        background-color: #f8f8f8;
        cursor: default;

        :hover {
            color: #ccc;
            background-color: #f8f8f8;
        }
    }
`;
