import { GQLFinanceType } from "@src/graphql.apollo.generated";
import useSearchFilter from "@src/shared/context/searchfilter/useSearchFilter";
import Richtext from "@src/shared/richtext/Richtext";
import React from "react";
import { FormattedMessage } from "react-intl";

import { DisclaimerContainer, StaticDisclaimer } from "../../SearchResults.sc";

interface DisclaimerProps {
    content: string;
}

const Disclaimer: React.FC<DisclaimerProps> = ({ content }) => {
    const { filter } = useSearchFilter();

    return (
        <DisclaimerContainer>
            <Richtext content={content} />

            <StaticDisclaimer>
                {filter.financeType === GQLFinanceType.leasing ? (
                    <FormattedMessage
                        id="leaseme.searchresult.ro.leasing.disclaimer"
                        defaultMessage="Finanzierungsleasing für hochwertige Gebrauchtwagen mit unserem speziellen Finanzierungs- und Versicherungsangebot. Wir zeigen ausschließlich Fahrzeuge an, die in Ihr Budget passen, um Ihnen alle Details des gewünschten Fahrzeugs in einer schnellen und einfachen Online-Erfahrung zu geben."
                    />
                ) : (
                    <FormattedMessage
                        id="leaseme.searchresult.ro.credit.disclaimer"
                        defaultMessage="Hochwertiger Gebrauchtwagenkredit mit unserem speziellen Finanzierungs- und Versicherungsangebot. Wir zeigen ausschließlich Fahrzeuge an, die in Ihr Budget passen, um Ihnen alle Details des gewünschten Fahrzeugs in einer schnellen und einfachen Online-Erfahrung zu geben."
                    />
                )}
            </StaticDisclaimer>
        </DisclaimerContainer>
    );
};

export default Disclaimer;
