import DisclaimerAT from "@src/AT/searchResults/vehicleSearchResults/disclaimer/Disclaimer";
import DisclaimerBG from "@src/BG/searchResults/vehicleSearchResults/disclaimer/Disclaimer";
import { getConfig } from "@src/config";
import { GQLLandingPageFragment, GQLVehicleSearchResultsQuery } from "@src/graphql.apollo.generated";
import { GQLVehicleSearchResultsPageQuery } from "@src/graphql.gatsby.generated";
import DisclaimerHU from "@src/HU/searchResults/vehicleSearchResults/disclaimer/Disclaimer";
import DisclaimerRO from "@src/RO/searchResults/vehicleSearchResults/disclaimer/Disclaimer";
import useSearchResult from "@src/shared/context/searchresult/useSearchResult";
import useSEOContent from "@src/shared/hooks/useSEOContent";
import LoadingSpinner from "@src/shared/loadingSpinner/LoadingSpinner";
import FilterToolbar from "@src/shared/searchResults/searchfilter/filter/FilterToolbar";
import SearchFilter from "@src/shared/searchResults/searchfilter/SearchFilter";
import Header from "@src/shared/searchResults/vehicleSearchResults/header/Header";
import { Pagination } from "@src/shared/searchResults/vehicleSearchResults/pagination/Pagination";
import SearchTitle from "@src/shared/searchResults/vehicleSearchResults/searchTitle/SearchTitle";
import VehicleList from "@src/shared/searchResults/vehicleSearchResults/vehiclelist/VehicleList";
import SEO from "@src/shared/seo/SEO";
import DisclaimerSI from "@src/SI/searchResults/vehicleSearchResults/disclaimer/Disclaimer";
import Master from "@src/skeleton/layout/Master";
import * as sc from "@src/skeleton/searchResults/SearchResults.sc";
import { graphql, PageProps } from "gatsby";
import * as React from "react";

export interface VehicleSearchResultPageContext {
    initialData: GQLVehicleSearchResultsQuery;
    schemeUrl: GQLLandingPageFragment;
}

const Disclaimer = ({ content }: { content: string }) => {
    switch (getConfig("scope").domain) {
        case "car4me.bg":
            return <DisclaimerBG content={content} />;
        case "car4me.ro":
            return <DisclaimerRO content={content} />;
        case "car4me.si":
            return <DisclaimerSI content={content} />;
        case "car4me.porschefinance.hu":
            return <DisclaimerHU content={content} />;
        default:
            return <DisclaimerAT content={content} />;
    }
};

const VehicleListResultTemplate: React.FunctionComponent<PageProps<GQLVehicleSearchResultsPageQuery, VehicleSearchResultPageContext>> = ({
    pageContext,
}) => {
    const { schemeUrl } = pageContext;

    const searchResult = useSearchResult();
    const { headline, title, description, content, noIndex } = useSEOContent(schemeUrl, searchResult.totalCount);

    const meta = [];

    if (noIndex) {
        meta.push({ name: "robots", content: "noindex, follow" });
    }

    const link = [{ rel: "canonical", href: `${getConfig("apiUrl")}/${schemeUrl.url}/` }];

    return (
        <Master sideBar={<SearchFilter />} backButtonHome={true} marginTop={80}>
            <SEO title={title} description={description} meta={meta} link={link} />
            <Header />
            <sc.Content>
                {searchResult.loading && (
                    <sc.LoadingSpinnerWrapper>
                        <LoadingSpinner />
                    </sc.LoadingSpinnerWrapper>
                )}
                <SearchTitle headline={headline} />
                <FilterToolbar />
                {searchResult.vehicles && <VehicleList vehicles={searchResult.vehicles} />}
                <Pagination />

                <Disclaimer content={content} />
            </sc.Content>
        </Master>
    );
};

export default VehicleListResultTemplate;

export const query = graphql`
    query VehicleSearchResultsPage($leasemeScope: LeaseMe_ContentScopeInput!) {
        leaseme {
            allVehicles(scope: $leasemeScope) {
                facets {
                    ...AllFacets
                }
            }
        }
    }
`;
