import * as React from "react";
import redraft, { Renderers } from "redraft";

import * as sc from "./Richtext.sc";

const renderers: Renderers = {
    blocks: {
        unstyled: (children) => children.map((child, index) => <sc.Body key={index}>{child}</sc.Body>),
        "header-one": (children) => children.map((child, index) => <sc.Heading key={index}>{child}</sc.Heading>),
    },
};

const Richtext: React.FunctionComponent<{ content: string | null }> = ({ content }) => {
    if (!content) {
        return null;
    }

    return <sc.Container>{redraft(JSON.parse(content), renderers)}</sc.Container>;
};

export default Richtext;
